<template>
  <div class="container">
    <div class="box">
      <div class="left">
        <img src="../../assets/logo.png" alt="" />
        <div style="margin-top:20px">
          中国领先的科技综合服务商
        </div>
      </div>
      <div class="midd">
        <li class="li-item"><img src="../../assets/phone.png" />售前咨询热线</li>
        <li class="li-text">020-83284903</li>
        <li class="li-item"><img src="../../assets/phone.png" />24小时客服热线</li>
        <li class="li-text">020-83284903</li>
        <li class="li-item"><img src="../../assets/email.png" />售前客服邮箱</li>
        <li class="li-text">topnet@5usport.com</li>
      </div>
      <!-- <div class="right">
        <h3></h3>
        <li></li>
      </div> -->
      <div class="code">
        <img src="../../assets/code.png" alt="" />
        <span style="margin-top: 10px">扫码关注我们</span>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-title">海南晌网数据网络发展有限公司网站</div>
      <div>
        Copyright @ 2020-2023 海南晌网数据网络发展有限公司 All rights
        reserved.&nbsp;
        <a href="https://beian.miit.gov.cn" target="_blank">
          <img
            src="../../assets/beiab.png"
            style="
              width: 18px;
              height: 18px;
              vertical-align: top;
              margin-right: 5px;
            "
          />
          <span>备案编号:琼ICP备20002123号-2</span></a
        >
      </div>
    </div>
  </div>
</template>

<<script>
export default {
  props:['footerObj'],
  data() {
    return {

    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  // position: absolute;
  //   bottom: 0;
  //   left: 0;
  // height: 250px;
  width: 100%;
  background-color: #000000;
  .box {
    padding-top:50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .left {
      // width:100px;
      height: 70px;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        // width: 100px;
        height: 100%;
      }
    }
    .midd {
      color: #fff;
      // float:left;
      text-align: left;
      li {
        margin-bottom: 20px;
        list-style: none;
        padding: 0px;
        // margin: 0px;
      }
      .li-item{
        display:flex;
        align-items: center;
        img{
          width:30px;
          height:30px;
          margin-right:10px
        }
      }
      .li-text{
        font-size:30px;
        color:#999999
      }
    }
    .right {
      color: #fff;
      text-align: left;
      li {
        margin-bottom: 20px;
        list-style: none;
        padding: 0px;
        // margin: 0px;
      }
    }
    .code {
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 100px;
        height: 100px;
      }
    }
  }
  .bottom {
    color: #fff;
    // background-color: #15366c;
    padding-top: 50px;
    padding-bottom: 50px;
    .bottom-title {
      text-align: center;
      margin-bottom: 10px;
    }
    a {
      // text-decoration: none;
      color: #fff;
    }
  }
}
</style>
