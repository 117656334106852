<template>
  <div class="home">
    <div>
      <img src="../assets/banner.png" alt="">
    </div>
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/footer/index.vue";

export default {
  name: "HomeView",
  components: {
    Footer,
  },
  mounted() {
    document.title = "海南晌网数据网络发展有限公司官方网站";
  },
};
</script>
<style>
.home {
  width: 100%;
  height: 100%;

  img{
    width: 100%;
  height: 100%;
  vertical-align: bottom;

  }
}
</style>
